import {
    pathOr,
    pluck
} from 'ramda';
import {TRANSACTION_DETAILS_STATE} from '../transactions.config';
import {stateGo} from 'redux-ui-router';
import {
    i18n,
    DateRangeShortcutHelper,
    MetadataActions,
    MetadataConstants,
    MetadataSelectors,
    SessionSelectors
} from 'invision-core';
import {RIGHT_ALIGNED_HEADER_TEMPLATE} from 'invision-ui/lib/components/collections/datatable/cellTemplates/cellTemplates';
import {
    NOTIFICATION_TIME_LENGTH
} from './../../../../customercare.constants';
import {
    CurrentCustomerIdSelector,
    CurrentCustomerSelector,
    RouteParams,
    RouteState
} from './../../../../reducers/selectors/customer.selectors';
import LocaleKeys from './../../../../locales/keys';
import * as TransactionActions from './../../../../reducers/actions/customer.transactions.actions';
import * as TransactionSelectors from './../../../../reducers/selectors/customer.transactions.selectors';
import {
    IsRetrievingEwalletDataSelector,
    PaymentInstrumentsSelector
} from './../../../../reducers/selectors/customer.ewallet.selectors';
import {retrieveWallet} from './../../../../reducers/actions/customer.ewallet.actions';

const PAGER_MAX_SLOTS = 5;

class TransactionsListController {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            paymentInstrumentIdLoadedFromUrl: false,
            LocaleKeys,
            onBannerClose: this.onBannerClose.bind(this),
            onBrandableCurrencySelected: this.onBrandableCurrencySelected.bind(this),
            onCurrencySelected: this.onCurrencySelected.bind(this),
            onDateRangeChanged: this.onDateRangeChanged.bind(this),
            onPageSelected: this.onPageSelected.bind(this),
            onPaymentInstrumentSelected: this.onPaymentInstrumentSelected.bind(this),
            onTransactionTypeSelected: this.onTransactionTypeSelected.bind(this),
            uiNotificationService
        });
    }

    $onInit() {
        this.columnDefs = [
            {
                field: 'Created',
                displayName: i18n.translate(LocaleKeys.TRANSACTIONS.COLUMNS.DATE),
                width: 145,
                enableColumnResizing: true,
                enableSorting: false
            },
            {
                field: 'Id',
                cellTemplate: require('./cellTemplates/id.template.html'),
                displayName: i18n.translate(LocaleKeys.TRANSACTIONS.COLUMNS.TRANSACTION_ID),
                width: 90,
                enableColumnResizing: true,
                enableSorting: false
            },
            {
                field: 'TypeName',
                displayName: i18n.translate(LocaleKeys.TRANSACTIONS.COLUMNS.TYPE),
                enableColumnResizing: true,
                enableSorting: false
            },
            {
                field: 'PurchasedProducts',
                displayName: i18n.translate(LocaleKeys.TRANSACTIONS.COLUMNS.DESCRIPTION),
                enableColumnResizing: true,
                enableSorting: false
            },
            {
                field: 'ResultName',
                cellTemplate: require('./cellTemplates/status.template.html'),
                displayName: i18n.translate(LocaleKeys.TRANSACTIONS.COLUMNS.RESULT),
                enableColumnResizing: true,
                enableSorting: false
            },
            {
                field: 'OriginalAmount',
                cellTemplate: require('./cellTemplates/smart.truncate.cell.template.html'),
                headerCellTemplate: RIGHT_ALIGNED_HEADER_TEMPLATE,
                displayName: i18n.translate(LocaleKeys.TRANSACTIONS.COLUMNS.PAYMENT),
                width: 150,
                enableColumnResizing: true,
                enableSorting: false
            },
            {
                field: 'TotalCreditAmount',
                cellTemplate: require('./cellTemplates/discount.template.html'),
                headerCellClass: 'u-pr+',
                headerCellTemplate: RIGHT_ALIGNED_HEADER_TEMPLATE,
                displayName: i18n.translate(LocaleKeys.TRANSACTIONS.COLUMNS.DISCOUNT),
                width: 150,
                enableColumnResizing: true,
                enableSorting: false
            },
            {
                field: 'Balance',
                cellTemplate: require('./cellTemplates/amount.template.html'),
                headerCellClass: 'u-pr++',
                displayName: i18n.translate(LocaleKeys.TRANSACTIONS.COLUMNS.AMOUNT),
                headerCellTemplate: RIGHT_ALIGNED_HEADER_TEMPLATE,
                minWidth: 100,
                enableColumnResizing: true,
                enableSorting: false
            }
        ];

        const mapStateToTarget = (store) => {
            return {
                areBrandableCurrenciesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.BrandableCurrency, store),
                areCurrenciesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.Currency, store),
                areTransactionTypesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.TransactionType, store),
                arrivedFromSearch: TransactionSelectors.CurrentTransactionFromSearchSelector(store),
                brandableCurrencies: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.BrandableCurrency, store),
                brandableCurrency: TransactionSelectors.BrandableCurrencySelector(store),
                brandableCurrencyOptions: TransactionSelectors.BrandableCurrencyCodeOptionsSelector(store),
                currencies: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.Currency, store),
                currency: TransactionSelectors.CurrencySelector(store),
                currencyOptions: TransactionSelectors.CurrencyCodeOptionsSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentRoute: RouteState(store),
                currentRouteParams: RouteParams(store),
                endDate: TransactionSelectors.EndDateSelector(store),
                endRecord: TransactionSelectors.EndRecordSelector(store),
                hasFiltersSelected: TransactionSelectors.HasSelectedTransactionFilters(store),
                hasLoadedTransactions: TransactionSelectors.HasLoadedTransactions(store),
                isFetchingData: TransactionSelectors.IsFetchingDataSelector(store),
                isFetchingEwalletData: IsRetrievingEwalletDataSelector(store),
                lockerItemId: TransactionSelectors.LockerItemIdSelector(store),
                maximumSlots: PAGER_MAX_SLOTS,
                numberOfPages: TransactionSelectors.TotalPagesSelector(store),
                pageNumber: TransactionSelectors.PageNumberSelector(store),
                paymentInstrument: TransactionSelectors.PaymentInstrumentSelector(store),
                paymentInstrumentIdUrlSearchParam: TransactionSelectors.PaymentInstrumentIdUrlSearchParamSelector(store),
                paymentInstrumentOptions: TransactionSelectors.PaymentInstrumentOptionsSelector(store),
                paymentInstruments: PaymentInstrumentsSelector(store),
                previousPageSubscription: TransactionSelectors.SubscriptionSelector(store),
                previousRoute: TransactionSelectors.PreviousRouteSelector(store),
                recordCount: TransactionSelectors.RecordCountSelector(store),
                selectedPageSizePreference: TransactionSelectors.SelectedPageSizePreference(store),
                selectedPaymentMethodId: TransactionSelectors.SelectedPaymentIdFromRouteSelector(store),
                selectedTransactionTypes: TransactionSelectors.TransactionTypesSelector(store),
                showSubscriptionBackBanner: TransactionSelectors.ShowSubscriptionBackBannerSelector(store),
                startDate: TransactionSelectors.StartDateSelector(store),
                startRecord: TransactionSelectors.StartRecordSelector(store),
                tableData: {
                    columnDefs : this.columnDefs,
                    data : TransactionSelectors.TableDataSelector(store)
                },
                transactionResults: TransactionSelectors.TransactionResultSelector(store),
                transactionResultsFilters: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.TransactionResult, store),
                transactionResultFiltersLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.TransactionResult, store),
                transactionTypes: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.TransactionType, store),
                userPageSizePreference: SessionSelectors.PageSizePreferenceSelector(store)
            };
        };

        const controllerActions = {
            fetchCodeType: MetadataActions.codes.fetchCodeTypes,
            retrieveWallet: retrieveWallet,
            searchTransactions: TransactionActions.searchTransactions,
            setBrandableCurrencyFilter: TransactionActions.setBrandableCurrencyFilter,
            setCurrencyFilter: TransactionActions.setCurrencyFilter,
            setCurrentPage: TransactionActions.setCurrentPage,
            setLockerFilter: TransactionActions.setLockerFilter,
            setPageSizePreference: TransactionActions.setPageSizePreference,
            setPaymentInstrumentFilter: TransactionActions.setPaymentInstrumentFilter,
            setTransactionResultFilter: TransactionActions.setTransactionResultFilter,
            setTransactionTypeFilter: TransactionActions.setTransactionTypeFilter,
            showSubscriptionBanner: TransactionActions.showSubscriptionBanner,
            stateGo,
            updateDateRange: TransactionActions.updateDateRange
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.stateOrName = this.state.currentRoute;
        this.optionalParams = {
            customerId: this.state.currentCustomerId
        };

        if (!this.state.previousPageSubscription && this.state.lockerItemId) {
            this.actions.setLockerFilter(null);
        }

        if (!this.state.areBrandableCurrenciesLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.BrandableCurrency);
        }

        if (!this.state.areCurrenciesLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.Currency);
        }

        if (!this.state.areTransactionTypesLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.TransactionType);
        }
        /**This logic is added for when we redirect from react RecentPaymentFailuresTile in Account Status widget to angular transactions page
         * the filter needs to be set to filter out the successful transactions,
         * we pass a flag setPaymentFailureFilter as true from react to identify the scenario and set the Transaction Result filter.
          */
        if (!this.state.transactionResultFiltersLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.TransactionResult).then((transactionResults) => {
                this.setFilterFailedTransactionScenarios(transactionResults.Codes || []);
            });
        }

        if (this.state.currentRouteParams.setPaymentFailureFilter && this.state.transactionResultFiltersLoaded) {
            this.setFilterFailedTransactionScenarios(this.state.transactionResultsFilters);
        }

        if (!this.state.isFetchingEwalletData) {
            this.actions.retrieveWallet({
                customerId: this.state.currentCustomerId,
                request: {
                    IncludeRemoved: false
                }
            }).catch((payload) => {
                this.uiNotificationService.error(payload.translatedMessage, null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            });
        }


        this.initDateRange(this.state.arrivedFromSearch);
    }

    get hasNoData() {
        return this.state.tableData.data.length === 0;
    }

    get shouldShowFilterBar() {
        return this.state.hasFiltersSelected || !this.hasNoData;
    }

    setFilterFailedTransactionScenarios(transactionFilters) {
        const SUCCESSFUL_TRANSACTION = '0';
        const failedTransactionScenarios = (transactionFilters || []).filter((transactionResult) => {
            return transactionResult.Value !== SUCCESSFUL_TRANSACTION;
        });
        this.actions.setTransactionResultFilter(failedTransactionScenarios);
        this.searchTransactions();
    }

    searchTransactions(fromSearchTransactions) {
        if (!this.state.selectedPageSizePreference) {
            this.actions.setPageSizePreference(this.state.userPageSizePreference);
        }

        const data = {
            BrandableCurrency: pathOr(null, ['id'], this.state.brandableCurrency),
            Currency: pathOr(null, ['id'], this.state.currency),
            End: this.state.endDate,
            LockerItemId: this.state.lockerItemId,
            PageNumber: this.state.pageNumber,
            PageSize: this.state.selectedPageSizePreference,
            PaymentInstrumentId: pathOr(null, ['id'], this.state.paymentInstrument),
            SortDirection: 2,
            Start: this.state.startDate,
            SubscriptionItemId: null,
            TransactionResults:  this.state.transactionResults ? pluck('Value')(this.state.transactionResults) : null,
            TransactionTypes: this.state.selectedTransactionTypes
        };

        if (this.state.paymentInstrumentIdUrlSearchParam && !this.paymentInstrumentIdLoadedFromUrl) {
            this.actions.setPaymentInstrumentFilter({
                id: +this.state.paymentInstrumentIdUrlSearchParam,
                value: +this.state.paymentInstrumentIdUrlSearchParam
            });
            data.PaymentInstrumentId = +this.state.paymentInstrumentIdUrlSearchParam;
            this.paymentInstrumentIdLoadedFromUrl = true;
        }
        this.actions.searchTransactions(this.state.currentCustomerId, data).then(() => {
            if (this.state.tableData.data.length === 1 && fromSearchTransactions) {
                this.actions.stateGo(TRANSACTION_DETAILS_STATE, {
                    transactionId: this.state.tableData.data[0].Id
                });
            }
        }).catch((payload) => {
            this.uiNotificationService.error(payload.translatedMessage, null, {
                timeOut: NOTIFICATION_TIME_LENGTH
            });
        });
    }

    initDateRange(fromSearchTransaction) {
        const entireHistoryDateShortcut = DateRangeShortcutHelper.getNullableOption(i18n.translate(LocaleKeys.ALL_TIME));
        this.shortcuts = [[entireHistoryDateShortcut].concat(DateRangeShortcutHelper.getShortListShortcuts()), DateRangeShortcutHelper.getRelativeShortListShortcuts()];
        this.selectedShortcut = entireHistoryDateShortcut;
        this.actions.updateDateRange(this.selectedShortcut.start, this.selectedShortcut.end);
        this.onPageSelected(1, fromSearchTransaction);
    }

    onDateRangeChanged(startDate, endDate) {
        this.actions.updateDateRange(startDate, endDate);
        this.onPageSelected(1);
    }

    onBrandableCurrencySelected(selectedItem) {
        this.actions.setBrandableCurrencyFilter(selectedItem);
        this.onPageSelected(1);
    }

    onCurrencySelected(selectedItem) {
        this.actions.setCurrencyFilter(selectedItem);
        this.onPageSelected(1);
    }

    onPageSelected(page, fromSearchTransactions = false) {
        this.actions.setCurrentPage(page);
        this.searchTransactions(fromSearchTransactions);
    }

    onPageSizeOptionSelected(pageSize) {
        this.actions.setPageSizePreference(pageSize);
        this.onPageSelected(1);
    }

    onPaymentInstrumentSelected(selectedItem) {
        this.actions.setPaymentInstrumentFilter(selectedItem);
        this.onPageSelected(1);
    }

    onTransactionTypeSelected(selectedItems) {
        this.actions.setTransactionTypeFilter(selectedItems);
        this.onPageSelected(1);
    }

    shouldShowBackBanner() {
        return this.state && this.state.previousPageSubscription && this.state.showSubscriptionBackBanner ||
            this.state.selectedPaymentMethodId &&
            !this.shouldHideBackBanner;
    }

    backBannerText() {
        if (pathOr(false, ['Items', 0, 'Product', 'Name'], this.state.previousPageSubscription)) {
            return this.subscriptionSecondaryNavigationLabel;
        } else if (this.state.selectedPaymentMethodId) {
            return this.walletSecondaryNavigationLabel;
        } else {
            return '';
        }
    }

    get subscriptionSecondaryNavigationLabel() {
        const previousName = this.state.previousPageSubscription.Items[0].Product.Name;
        return i18n.translate(LocaleKeys.TRANSACTIONS.RETURN_TO, {
            previousName
        });
    }

    get walletSecondaryNavigationLabel() {
        const previousName = i18n.translate(LocaleKeys.E_WALLET.TITLE);
        return i18n.translate(LocaleKeys.TRANSACTIONS.RETURN_TO, {
            previousName
        });
    }

    onBannerClose() {
        if (this.state.selectedPaymentMethodId) {
            this.shouldHideBackBanner = true;
        } else {
            this.actions.showSubscriptionBanner(false);
        }
    }

    onRemoveSubscriptionFilter() {
        this.actions.setLockerFilter(null);
        this.onPageSelected(1);
    }

    removeTransactionResultFilter() {
        this.actions.setTransactionResultFilter([]);
        this.searchTransactions();
    }

    $onDestroy() {
        this.actions.setTransactionResultFilter([]);
        this.disconnectRedux();
    }
}


export default {
    bindings: {},
    controller: TransactionsListController,
    controllerAs: 'controller',
    template: require('./transactions.list.html')
};
